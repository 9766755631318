import VueWithCompiler from "vue/dist/vue.esm";
import {sleep} from "./helpers";

export const RenderUtils = {
  data() {
    return { templateRender: undefined };
  },
  watch: {
    async html() {
      await this.updateRender();
    }
  },
  async created() {
    await this.updateRender();
  },
  methods: {
    async updateRender() {
      await sleep(50); // sleep prevents garbling/distortion of components during render
      let compiled = VueWithCompiler.compile(this.html);
      this.templateRender = compiled.render;
      this.$options.staticRenderFns = [];
      for (const staticRenderFunction of compiled.staticRenderFns) {
        this.$options.staticRenderFns.push(staticRenderFunction);
      }
    }
  },
  render() {
    // return this.templateRender || this.templateRender();
    if (this.templateRender !== undefined) return this.templateRender();
    else "";
  }
};
